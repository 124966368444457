import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { graphql } from 'gatsby';

import { siteMetadata } from '~config/site';
import WeaponMeta from '~templates/meta/WeaponMeta';

import BackArrow from '~components/BackArrow';
import Container from '~components/Container';
import CamoContainer from '~components/CamoContainer';
import TipList from '~components/TipList';
import NonHome from '~templates/NonHome';

export default function Weapon(props) {
    const weapon = get(props, 'data.weapon');
    const weaponCategory = get(props, 'data.weaponCategory');
    const tips = get(props, 'data.weapon.tips');

    // const siteDomain = get(
    //     this.props,
    //     'data.site.siteMetadata.siteUrl',
    //     ''
    // );
    // const postUrl = `${siteDomain}${get(
    //     this,
    //     'props.pageContext.slug',
    //     ''
    // )}`;
    function renderTips() {
        let content;

        if (tips && tips.length) {
            content = <TipList tips={tips} />;
        } else {
            content = (
                <p>
                    No tips yet for the {weapon.name}. Know of something useful?
                    Hit me up{' '}
                    <a
                        href={`https://twitter.com/${siteMetadata.twitterHandle}`}
                    >
                        @{siteMetadata.twitterHandle}
                    </a>
                </p>
            );
        }

        return (
            <Fragment>
                <h3>{weapon.name} General Tips</h3>
                {content}
            </Fragment>
        );
    }

    return (
        <NonHome location={props.location}>
            <WeaponMeta weapon={weapon} />
            <Container>
                <h1>
                    <BackArrow
                        path={weaponCategory.slug}
                        title={`Back to ${weaponCategory.name} class`}
                    />{' '}
                    {weapon.name}
                </h1>
            </Container>
            <Container>{renderTips()}</Container>

            <CamoContainer weapon={weapon} weaponCategory={weaponCategory} />
        </NonHome>
    );
}

Weapon.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
    pageContext: PropTypes.object,
};

export const pageQuery = graphql`
    query WeaponPageQuery($id: String, $parentId: String) {
        site {
            siteMetadata {
                title
                author
                siteUrl
            }
        }
        weapon(id: { eq: $id }) {
            id
            name
            requiredForDamascus
            slug
            tips {
                text
            }
            camos {
                levelUnlocked
                description
                targetAmounts
                tips {
                    text
                }
            }
        }
        weaponCategory(id: { eq: $parentId }) {
            id
            name
            slug
            camos {
                description
            }
        }
    }
`;
