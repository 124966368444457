import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import Modal from '~components/modal/Modal';
import TipList from '~components/TipList';
import { siteMetadata } from '~config/site';
import CamoButton from './CamoButton';
import Container from './Container';
import styles from './CamoContainer.module.scss';

export default function CamoContainer({
    displayCompleted,
    weapon,
    weaponCategory,
}) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [activeCamoIndex, setActiveCamoIndex] = useState(false);
    const {
        allCamo: { nodes: allCamo },
    } = useStaticQuery(graphql`
        query CamoQuery {
            allCamo {
                nodes {
                    id
                    name
                }
            }
        }
    `);

    function handleCamoClick(e) {
        setActiveCamoIndex(
            parseInt(e.currentTarget.getAttribute('data-index'))
        );
        openModal();
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function renderCamoModal() {
        const camo = allCamo[activeCamoIndex];
        const camoName = camo.name;
        const title = `${weapon.name}: ${camoName}`;
        const weaponOrCategoryCamos = weapon.camos || weaponCategory.camos;
        const tips = weaponOrCategoryCamos[activeCamoIndex].tips;
        let content;

        if (tips && tips.length) {
            content = <TipList tips={tips} />;
        } else {
            content = (
                <p>
                    No tips yet for the {camoName} camo. Know of something
                    useful? Hit me up{' '}
                    <a
                        href={`https://twitter.com/${siteMetadata.twitterHandle}`}
                    >
                        @{siteMetadata.twitterHandle}
                    </a>
                </p>
            );
        }

        return (
            <Modal
                title={title}
                subtitle={weaponOrCategoryCamos[activeCamoIndex].description}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <img
                    className={styles.image}
                    src={`/camos/${activeCamoIndex + 1}/1.jpg`}
                    alt=""
                />

                <div className={styles.modalContent}>{content}</div>

                <button onClick={closeModal}>
                    <span>Close</span>
                </button>
            </Modal>
        );
    }

    return (
        <Container>
            <h3>Choose a camo</h3>
            <div className={styles.container}>
                {allCamo.map((camo, i) => {
                    return (
                        <CamoButton
                            key={camo.id}
                            title={camo.name}
                            displayCompleted={displayCompleted}
                            completed={0}
                            camoIndex={i}
                            patternIndex={0}
                            onClick={handleCamoClick}
                        />
                    );
                })}
            </div>
            {modalIsOpen && renderCamoModal()}
        </Container>
    );
}

CamoContainer.propTypes = {
    weapon: PropTypes.object,
    weaponCategory: PropTypes.object,
    displayCompleted: PropTypes.bool,
};
