import React from 'react';
import PropTypes from 'prop-types';

import styles from './CamoButton.module.scss';

export default function CamoButton({
    title,
    completed,
    camoIndex,
    patternIndex,
    displayCompleted,
    disabled,
    onClick,
}) {
    return (
        <button
            className={styles.container}
            onClick={onClick}
            disabled={disabled}
            data-index={camoIndex}
        >
            <div className={styles.text}>
                <p className={styles.title}>{title}</p>
                {displayCompleted && (
                    <p className={styles.completed}>{completed}/10</p>
                )}
            </div>
            <img
                className={styles.image}
                src={`/camos/${camoIndex + 1}/${patternIndex + 1}.jpg`}
                onLoad={(e) => (e.currentTarget.style.opacity = 1)}
                style={{ opacity: 0 }}
                alt=""
            />
        </button>
    );
}

CamoButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    displayCompleted: PropTypes.bool,
    completed: PropTypes.number,
    camoIndex: PropTypes.number,
    patternIndex: PropTypes.number,
};

CamoButton.defaultProps = {
    completed: 0,
    displayCompleted: false,
    disabled: false,
};
